import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { property as propertyPropType, monthlyDistributions as monthlyDistributionsPropType } from 'scripts/constants/PropTypes';
import { monthlyPaidDistributionsSelector } from 'scripts/redux/selectors/property';
import { fetchMonthlyPaidDistributions } from 'scripts/redux/actions/property';

import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import FinancialsBox from 'scripts/components/property/FinancialsBox';

import { percentDecimal, date, dollar, monthYear } from 'scripts/utilities/formatters';
import Constants from 'scripts/constants/Constants';
import _ from 'lodash';


const mapStateToProps = (state) => ({
  ...monthlyPaidDistributionsSelector(state),
});

@connect(mapStateToProps, {
  fetchMonthlyPaidDistributions
})
export default class MonthlyPaidDistributions extends Component {
  static propTypes = {
    monthlyPaidDistributions: monthlyDistributionsPropType,
    property: propertyPropType.isRequired,
    fetchMonthlyPaidDistributions: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { property, fetchMonthlyPaidDistributions } = this.props;
    fetchMonthlyPaidDistributions(property.propertyCode);
  }

  render() {
    const { property, monthlyPaidDistributions } = this.props;
    const hasMonthlyDistributionPaid = _.isNil(monthlyPaidDistributions) ? false : !_.isEmpty(monthlyPaidDistributions);
    const title = 'Monthly Distributions';
    const rentYieldBoxDescription = property.tenantStatus.tenanted ? Constants.ANNUALISED : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;
    const rentPerWeekBoxDescription = property.tenantStatus.tenanted ? Constants.CURRENTLY_TENANTED : Constants.FORECASTED;
    return (
      <FinancialsPanel
        titleIcon="icn icn-small icn-coins" className="panel-financials-transparent-header"
        title={title}
        linkText="See Monthly Distributions tab"
        link={`/properties/${property.propertyCode}/distributions`}>
        <div className="row">
          <FinancialsBox
            className="col-md-4 yield-rental-income financials-box-small"
            title={"ESTIMATED NET RENTAL YIELD"}
            value={(property.financials.netRentalYield)::percentDecimal()}
            description={rentYieldBoxDescription}
          />
          <FinancialsBox
            className="col-md-4 rental-yield-rent financials-box-small"
            title={"RENT PER WEEK"}
            value={(property.financials.weeklyRentalIncome)::dollar()}
            description={rentPerWeekBoxDescription}
          />
          <div>
            {hasMonthlyDistributionPaid
              ?
              <FinancialsBox
                className="col-md-4 last-distribution-paid financials-box-small"
                title={"LAST DISTRIBUTION"}
                value={`${(monthlyPaidDistributions[0].distributionDate)::monthYear()}`}
                description={`PAID: ${(monthlyPaidDistributions[0].paidDate)::date()}`}
              />
              :
              <FinancialsBox
                className="col-md-4 net-income-per-year financials-box-small"
                title={"ESTIMATED NET INCOME"}
                value={(property.financials.annualNetRentalIncome)::dollar()}
                description={property.tenantStatus.tenanted ? Constants.PER_YEAR : `${Constants.PER_YEAR} - ${Constants.FORECASTED}`}
              />
            }
          </div>
        </div>
      </FinancialsPanel>
    );
  }
}
