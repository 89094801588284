import PropertyCardStat from 'src/components/propertyCards/common/propertyCardStat/PropertyCardStat';
import _ from 'lodash';
import { ForecastCapitalGrowth } from 'src/settings/financials';
import Formatters from 'scripts/utilities/formattersV2';

const extra = (property) => {
  if (
    _.includes(
      ['CLN01', 'CLN02', 'CLN03', 'TAR01', 'WYN01'],
      property.propertyCode
    )
  ) {
    return (
      <div>
        <small>Excluding performance fee</small>
      </div>
    );
  }
};

const EstNetRentalYieldStat = ({ property }) => {
  if (ForecastCapitalGrowth[property.propertyCode]) {
    return (
      <PropertyCardStat
        label={<span>Development Objective Capital Growth Rate</span>}
        value={
          <span>
            {Formatters.numeric.percent(
              ForecastCapitalGrowth[property.propertyCode]
            )}{' '}
            <small>p.a.</small>
          </span>
        }
      />
    );
  }
  return (
    <PropertyCardStat
      label={<span>Est. Net Rental Yield {extra(property)}</span>}
      value={
        <span>
          {Formatters.numeric.percentDecimal(
            property.financials.netRentalYield
          )}{' '}
          <small>p.a.</small>
        </span>
      }
      valueProps={{ 'data-test-reference': 'net-rental-yield' }}
    />
  );
};

export default EstNetRentalYieldStat;
