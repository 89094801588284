import { Fragment } from 'react';
import styled from 'styled-components';
import { SubHeading, HeroSectionHeading } from 'src/design/styleguide/Headings';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import { getThemeColor } from 'src/themes/themeUtils';
import { gridUnit } from 'src/design/styleguide/common/measurements';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import Icon from 'src/design/components/icon/Icon';
import Paragraph from 'src/design/styleguide/Paragraph';
import PropertyTeamMeta from 'src/components/meta/PropertyTeamMeta';
import SectionHeading from 'src/components/landingPages/howItWorks/common/sectionHeading/SectionHeading';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import TeamMemberBioCard from 'src/components/teamMemberBioCard/TeamMemberBioCard';
import TeamSection from 'src/components/propertyTeam/TeamSection';
import Text from 'src/design/styleguide/text/Text';

const PartnerLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PartnerLogoImage = styled.img`
  width: 100%;
`;

const StyledPartnerLogo = styled.div`
  flex: 0 0 200px;
  padding: 0 ${gridUnit * 4}px ${gridUnit * 8}px ${gridUnit * 4}px;
`;

const PartnerLogo = ({ className, ...props }) => (
  <StyledPartnerLogo className={className}>
    <PartnerLogoImage {...props} />
  </StyledPartnerLogo>
);

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 ${gridUnit * 30}px;
`;

const StyledIcon = styled(Icon)`
  font-size: 40px;
  color: ${getThemeColor('primary')};
`;

const StyledBigIcon = styled(StyledIcon)`
  font-size: 70px;
`;

const StyledIconText = styled(Text)`
  font-size: ${gridUnit * 5}px;
`;

const InvestmentStrategyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpointSmall(`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  `)}
`;

const InvestmentStrategyContentItem = styled.div`
  max-width: 450px;

  &:last-child {
    margin-top: ${gridUnit * 6}px;
  }

  ${breakpointSmall(`
    margin: 0 ${gridUnit * 4}px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-top: 0;
      margin-right: 0;
    }
  `)}
`;

const PropertyTeam = () => (
  <Fragment>
    <PropertyTeamMeta />
    <ContentBlock
      topPadding="medium"
      bottomPadding="x-large"
      backgroundImage="/static/img/photo-hand-showing-ipad-to-someone.jpg"
    >
      <ContentContainer fontSize="small">
        <HeroSectionHeading>THE BRICKX TEAM</HeroSectionHeading>

        <Spacing top="normal">
          <SectionHeading>
            BrickX's Management Team comes from a cross section of professional
            experience in the property and investment sectors.
          </SectionHeading>
        </Spacing>

        <Spacing top="2x-large">
          <IconContainer>
            <IconAndText>
              <StyledIcon type={Icon.types.investment} />
              <StyledIconText>Investment</StyledIconText>
            </IconAndText>

            <IconAndText>
              <StyledIcon type={Icon.types.adviser} />
              <StyledIconText>Adviser</StyledIconText>
            </IconAndText>

            <IconAndText>
              <StyledIcon type={Icon.types.buying} />
              <StyledIconText>Buying</StyledIconText>
            </IconAndText>
          </IconContainer>
        </Spacing>
      </ContentContainer>
    </ContentBlock>

    <TeamSection
      topPadding="large"
      title="The Management Team"
      description="The BrickX Management Team draws on decades of commercial experience across property, diversified investments, financial and technology industries to deliver a comprehensive and diversified selection of investments to the BrickX Platform."
      cards={[
        <TeamMemberBioCard
          imageSrc="/static/img/profile_joe.png"
          possessiveName="Joe's"
          title="Joe Galvez"
          subtitle="CEO"
          description={
            <Fragment>
              <Paragraph>
                Joe co-founded Thundering Herd, having brought to it over 20
                years’ investment experience, having held senior financial
                advisory positions at Macquarie Bank where he was a member of
                the National Adviser Counsel, Citi Smith Barney where he was
                Senior Vice President and Merrill Lynch where he co-managed a
                debt book backed by both equity and derivative positions.
              </Paragraph>
              <Paragraph>
                Joe has advised and invested in numerous companies ranging from
                early stage start ups through to publicly listed companies,
                successfully exiting his wealth management business venture
                which amassed in excess of $500m + in assets under advice.
              </Paragraph>
              <Paragraph>
                Joe is the Responsible Manager of the Australian Financial
                Services License that Thundering Herd operates under and also
                holds various Board positions relating to investee companies of
                Thundering Herd.
              </Paragraph>
            </Fragment>
          }
        />,
        <TeamMemberBioCard
          imageSrc="/static/img/profile_davidrees.png"
          possessiveName="David's"
          title="David Rees"
          subtitle="Chief Financial Officer"
          description={
            <Fragment>
              <Paragraph>
                David has over 10 years of finance and professional services
                experience across a broad range of industries such as media,
                travel, entertainment & telecommunications. His expertise is
                centred around internal management reporting, external
                reporting, capital allocation, business performance and
                commercial management.{' '}
              </Paragraph>
              <Paragraph>
                David’s focus areas within Thundering Herd include accounting &
                financial reporting, investor relations and corporate finance.
              </Paragraph>
            </Fragment>
          }
        />,
        <TeamMemberBioCard
          imageSrc="/static/img/profile_claude.png"
          possessiveName="Claude's"
          title="Claude Chen"
          subtitle="Chief Technology Officer"
          description={
            <Fragment>
              <Paragraph>
                Claude is a dynamic and versatile full-stack developer that has
                comprehensive experience in Front-end Development, Back-end
                Development, DevOps as well as machine learning and data
                management. Previously, Claude worked as a chief software
                engineer in a hedge fund company and has extensive experience
                and well understanding of information technology in financial
                services. Claude is capable of utilising every facet of
                engineering team to deliver key projects for the business.
              </Paragraph>
              <Paragraph>
                Claude leads the technology operation, research, evaluation and
                development in BrickX.
              </Paragraph>
            </Fragment>
          }
        />,
      ]}
    />
    <TeamSection
      cards={[
        <TeamMemberBioCard
          imageSrc="/static/img/profile_lucinda.png"
          possessiveName="Lucinda's"
          title="Lucinda Sgro"
          subtitle="Head of Product & CX"
          description={
            <Fragment>
              <Paragraph>
                Lucinda has over 10 years experience in law and technology
                across various industries. Lucinda specialised in property,
                building & construction, insurance and corporate law. She has
                since gained broad experience working with early stage
                technology businesses across a range of areas including legal,
                operations, product, customer success, strategy and growth into
                new markets.
              </Paragraph>
              <Paragraph>
                Lucinda is Head of Product and Customer Experience at BrickX.
              </Paragraph>
            </Fragment>
          }
        />,
        <TeamMemberBioCard
          imageSrc="/static/img/profile_michael.png"
          possessiveName="Michael's"
          title="Michael Brown"
          subtitle="Investment Manager"
          description={
            <Fragment>
              <Paragraph>
                Michael has extensive experience in the financial services
                industry having held senior positions in both funds management
                and stock broking companies.
              </Paragraph>
              <Paragraph>
                Michael was the Head of Australian Equities at the Myer Family
                Company Limited for over 7 years and co-managed the Aviva
                Investors “High Growth Fund” for 4.5 years. Over the past ten
                years, Michael has managed investment projects, developments and
                financing syndications valued at over $100m worth of commercial,
                residential and retail property transactions across Australia.{' '}
              </Paragraph>
              <Paragraph>
                Michael is on the Board of BrickX Financial Services Limited.
              </Paragraph>
            </Fragment>
          }
        />,
      ]}
    />
    {/* <TeamSection
      cards={[
        <TeamMemberBioCard
        imageSrc="/static/img/profile_michael.png"
        possessiveName="Michael's"
        title="Michael Brown"
        subtitle="Investment Manager"
        description={
          <Fragment>
            <Paragraph>Michael has extensive experience in the financial services industry having held senior positions in both funds management and stock broking companies.</Paragraph>
            <Paragraph>Michael was the Head of Australian Equities at the Myer Family Company Limited for over 7 years and co-managed the Aviva Investors “High Growth Fund” for 4.5 years. Over the past ten years, Michael has managed investment projects, developments and financing syndications valued at over $100m worth of commercial, residential and retail property transactions across Australia. </Paragraph>
            <Paragraph>Michael is on the Board of BrickX Financial Services Limited.</Paragraph>
          </Fragment>
        }
      />,
      ]}
    /> */}
    <ContentBlock
      topPadding="large"
      bottomPadding="large"
      backgroundColor="lightgrey"
    >
      <ContentContainer size="small">
        <SectionHeading>Our Partners</SectionHeading>
        <Spacing top="normal">
          <Paragraph align="center">
            We are proud to be supported by the following Suppliers and
            Partners.
          </Paragraph>
        </Spacing>
        <Spacing top="2x-large">
          <PartnerLogosContainer>
            <PartnerLogo src="/static/img/logo-core-logic.svg" />
            <PartnerLogo src="/static/img/logo-thundering-herd.png" />
            <PartnerLogo src="/static/img/logo-HWL.png" />
            <PartnerLogo src="/static/img/logo-grant-thornton.png" />
            <PartnerLogo src="/static/img/logo-nab.png" />
            <PartnerLogo src="/static/img/logo-macquarie.png" />
          </PartnerLogosContainer>
        </Spacing>
      </ContentContainer>
    </ContentBlock>
    <ContentBlock
      topPadding="large"
      bottomPadding="x-large"
      backgroundColor="grey"
    >
      <InvestmentStrategyContentContainer>
        <InvestmentStrategyContentItem>
          <IconContainer>
            <StyledBigIcon type="investment_philosophy" />
          </IconContainer>
          <SubHeading as="h3" fontSize="x-small">
            Our Investment Philosophy
          </SubHeading>
          <Spacing top="x-small">
            <Paragraph align="center">
              All our property acquisitions are based on a purely results driven
              mandate, with the sole purpose of securing BrickX investors
              balanced and diversified investment opportunities.
            </Paragraph>
          </Spacing>
        </InvestmentStrategyContentItem>
        <InvestmentStrategyContentItem>
          <IconContainer>
            <StyledBigIcon type="acquisition_strategy" />
          </IconContainer>
          <SubHeading as="h3" fontSize="x-small">
            Our Acquisition Strategy
          </SubHeading>
          <Spacing top="x-small">
            <Paragraph align="center">
              The Property Team meets quarterly, and reviews the immediate and
              ongoing needs of the BrickX Property Portfolio. Taking into
              account market conditions and the BrickX community, the Team aims
              to keep the property acquisition strategy responsive.
            </Paragraph>
          </Spacing>
        </InvestmentStrategyContentItem>
      </InvestmentStrategyContentContainer>
    </ContentBlock>
  </Fragment>
);

export default PropertyTeam;
