import PropTypes from 'prop-types';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  percentDecimal,
  date,
  dollar,
  dollarDecimal,
  month,
  nextMonthYear,
} from 'scripts/utilities/formatters';
import Markdown from 'react-remarkable';
import Constants from 'scripts/constants/Constants';
import _ from 'lodash';
import { renderCode } from 'src/settings/properties';

const KeyMetrics = ({
  property,
  isPreMarketOpenProperty,
  monthlyDistributions,
  monthlyUpdates,
}) => {
  const title = 'Monthly Distributions: Key Metrics';

  const rentBoxDescription = property.tenantStatus.tenanted
    ? Constants.CURRENTLY_TENANTED
    : Constants.FORECASTED;
  const rentIncomeBoxDescription = property.tenantStatus.tenanted
    ? Constants.CURRENTLY_TENANTED
    : `${Constants.PER_YEAR} - ${Constants.FORECASTED}`;
  const rentYieldBoxDescription = property.tenantStatus.tenanted
    ? Constants.ANNUALISED
    : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;
  const noDistributionPaidBoxTitle = 'Est. Gross Rental Yield';
  const noDistributionPaidBoxValue =
    property.financials.grossRentalYield::percentDecimal();
  const noDistributionPaidBoxDescription = property.tenantStatus.tenanted
    ? Constants.ANNUALISED
    : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;

  const noDistributionPaidText = isPreMarketOpenProperty
    ? `The first distribution on ${renderCode(
        property.propertyCode
      )} is estimated to be paid in ${property.platformSettlementDate::nextMonthYear()}`
    : '';

  const lastDistribution = monthlyDistributions[0];
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title={`${title}`}
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 key-metrics-rent financials-box-small"
          title={'RENT PER WEEK'}
          value={property.financials.weeklyRentalIncome::dollar()}
          description={rentBoxDescription}
        />
        <FinancialsBox
          className="col-md-3 net-income-per-year financials-box-small"
          title={'ESTIMATED NET INCOME'}
          value={property.financials.annualNetRentalIncome::dollar()}
          description={rentIncomeBoxDescription}
        />
        <FinancialsBox
          className="col-md-3 net-rental-yield financials-box-small"
          title={'ESTIMATED NET RENTAL YIELD'}
          value={property.financials.netRentalYield::percentDecimal()}
          description={rentYieldBoxDescription}
        />
        {lastDistribution ? (
          <FinancialsBox
            className="col-md-3 last-distribution-paid financials-box-small"
            title={`${lastDistribution.distributionDate::month()} DISTRIBUTION `}
            value={lastDistribution.amount::dollarDecimal()}
            description={`${
              lastDistribution.amount > 0 ? 'PAID' : 'DATE'
            }: ${lastDistribution.paidDate::date()}`}
          />
        ) : (
          <FinancialsBox
            className="col-md-3 last-distribution-paid financials-box-small"
            title={noDistributionPaidBoxTitle}
            value={noDistributionPaidBoxValue}
            description={noDistributionPaidBoxDescription}
          />
        )}
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          {!_.isEmpty(monthlyUpdates) ? (
            <Markdown source={monthlyUpdates[0].monthlyDistributionInfo} />
          ) : (
            noDistributionPaidText
          )}
        </div>
      </div>
    </FinancialsPanel>
  );
};

KeyMetrics.propTypes = {
  property: propertyPropType.isRequired,
  isPreMarketOpenProperty: PropTypes.bool.isRequired,
};

export default KeyMetrics;
