import { dollar } from 'src/utils/formats';

export const maximumAustralianTaxAmount = 0.47;
export const refereeGiftAmount = dollar(40);
export const referrerGiftAmount = dollar(30);
export const getTransactionFeeRatio = () => 0.005;

/** HARD-CODED forecast fund return */
export const ForecastCapitalGrowth = {
  ADI01: 0.1,
  LCF01: 0.1,
  KPI01: 0.1,
  NEW01: 0.08,
  SSL01: 0.14,
};

/** HARD-CODED initial debt */
export const InitialDebt = {
  ANN01: 381000.0,
  DBB01: 445000.0,
  BND01: 490000.0,
  DAR01: 336000.0,
  PPT01: 292050.0,
  SUR01: 455000.0,
  BRW01: 309400.0,
  MAN01: 321900.0,
  STP01: 165020.0,
  KIN01: 170250.0,
  SLD01: 120300.0,
  BAN01: 121200.0,
  KGT01: 312000.0,
};

export const InitialEquity = {
  ANN01: 889000.0,
  DBB01: 860000.0,
  BND01: 860001.0,
  DAR01: 504000.0,
  PPT01: 592950.0,
  SUR01: 845000.0,
  BRW01: 880600.0,
  MAN01: 788100.0,
  STP01: 660080.0,
  KIN01: 397250.0,
  SLD01: 280700.0,
  BAN01: 284790.0,
  KGT01: 728000.0,
};

export const CashReserve = {
  MAN01: 14839.0,
  STP01: 9209.0,
  BAN01: 17704.0,
};
