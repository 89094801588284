import Markdown from 'react-remarkable';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpointMedium } from 'src/design/styleguide/common/breakpoints';
import { date, monthYear, dollar, brickPrice } from 'scripts/utilities/formatters';
import { propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription, historicalGrowthDataProvider, historicalGrowthTitle } from 'src/settings/text';
import { property as propertyPropType, monthlyUpdates as monthlyUpdatesPropType } from 'scripts/constants/PropTypes';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

const StyledFinancialsBox = styled(FinancialsBox)`
  @media (min-width: 980px) {
    margin-bottom: 15px;
  }

  ${breakpointMedium(`
    flex: 1 0 0px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    & .gray-box {
      height: 100%;
    }
  `)}
`;

const KeyMetricsBoxesContainer = styled.div`
  @media (min-width: 980px) {
    display: flex;
    flex-direction: column;
  }

  ${breakpointMedium(`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

const KeyMetrics = ({ property, isPreorderOrComingSoonProperty, monthlyUpdates, averageAnnualGrowthAmount, averageAnnualGrowthNumberOfYears }) => {
  const isPreOrderOrNotIndependentValuedProperty = isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;

  const title = 'Capital Returns: Key Metrics';
  const latestValueBoxTitle = isPreOrderOrNotIndependentValuedProperty ? 'Total Purchase Cost' : 'Latest Value';
  const latestValueBoxDescription = isPreOrderOrNotIndependentValuedProperty ? (property.platformSettlementDate)::date() : (property.financials.latestValuationDate)::monthYear();

  const brickValueBoxTitle = isPreOrderOrNotIndependentValuedProperty ? 'INITIAL BRICK PRICE' : 'BRICK VALUE';
  const brickValueBoxDescription = isPreOrderOrNotIndependentValuedProperty ? (property.platformSettlementDate)::date() : (property.financials.latestValuationDate)::monthYear();

  const debtBoxDescription = isPreOrderOrNotIndependentValuedProperty ? (property.platformSettlementDate)::date() : (property.financials.latestValuationDate)::monthYear();

  return (
    <FinancialsPanel className="panel-financials-transparent-header panel-financials-capital-returns"
      title={title}
      titleIcon="icn-line-chart-blue"
    >
      <KeyMetricsBoxesContainer>
        <StyledFinancialsBox className="five-year-historical-suburb-growth financials-box-small"
          title={historicalGrowthTitle(property.propertyType)}
          value={averageAnnualGrowthAmount}
          description={propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription(averageAnnualGrowthNumberOfYears, historicalGrowthDataProvider(property.propertyType))}
          valueTestRef="capital-returns-value"
          descriptionTestRef="capital-returns-description"
        />
        <StyledFinancialsBox className="financials-box-small latest-value"
          title={latestValueBoxTitle}
          value={(property.financials.latestValue)::dollar()}
          description={latestValueBoxDescription}
        />
        <StyledFinancialsBox className="financials-box-small outstanding-debt"
          title={`DEBT`}
          value={property.financials.debtTerms ? property.financials.debtTerms.outstandingDebt::dollar() : '$0' }
          description={debtBoxDescription}
        />
        <StyledFinancialsBox className="financials-box-small brick-value"
          title={brickValueBoxTitle}
          value={(property.financials.brickValue)::brickPrice()}
          description={brickValueBoxDescription}
        />
      </KeyMetricsBoxesContainer>
      <div className="row">
        <div className="col-md-12 monthly-update">
          {monthlyUpdates.length ? <Markdown source={monthlyUpdates[0].brickValuationInfo}/> : ''}
        </div>
      </div>
    </FinancialsPanel>
  );
};


KeyMetrics.propTypes = {
  property: propertyPropType.isRequired,
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
  monthlyUpdates: monthlyUpdatesPropType,
  averageAnnualGrowthAmount: PropTypes.string.isRequired
};


export default KeyMetrics;
