import React, { Component } from 'react';
import { Link } from 'react-router';
import PdsMeta from 'src/components/meta/PdsMeta';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  fsgDirectUrl,
  pdsDirectUrl,
  tmdUrl,
} from 'src/utils/pageUrls';
import Paragraph from 'src/design/styleguide/Paragraph';

export default class PdsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="pds-page">
        <PdsMeta />
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1 smsf-page">
              <h1>Financial Services Guide</h1>
              <Paragraph className="text-center">
                The Financial Services Guide can be downloaded as a PDF by
                clicking on the link below.
              </Paragraph>
            </div>
            <Spacing top="normal">
              <div className="col-md-10 col-md-offset-1 text-center">
                <Link
                  to={fsgDirectUrl()}
                  target="_blank"
                  className="doc-download"
                >
                  <div className="button orange-button right-arrow-button">
                    DOWNLOAD FINANCIAL SERVICES GUIDE
                  </div>
                </Link>
              </div>
            </Spacing>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1 smsf-page">
              <h1>Target Market Determination </h1>
              <Paragraph className="text-center">
                The Target Market Determination can be downloaded as a PDF by
                clicking on the link below.
              </Paragraph>
            </div>
            <Spacing top="normal">
              <div className="col-md-10 col-md-offset-1 text-center">
                <Link to={tmdUrl()} target="_blank" className="doc-download">
                  <div className="button orange-button right-arrow-button">
                    DOWNLOAD TARGET MARKET DETERMINATION
                  </div>
                </Link>
              </div>
            </Spacing>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1 smsf-page">
              <h1>Product Disclosure Statement</h1>
              <Paragraph className="text-center">
                The Product Disclosure Statement can be downloaded as a PDF by
                clicking on the link below. Before making any decision in
                relation to BrickX or any product offered by BrickX you should
                read the Product Disclosure Statement (PDS), Additional PDS for
                CLN01 and consider whether it is right for you. You should also
                review the information set out in the RG46 Schedule before
                making an investment decision.
              </Paragraph>
              <Paragraph className="text-center">
                BrickX products are issued by BrickX Financial Services Limited
                (ABN 67 616 118 088) (AFSL 494878)
              </Paragraph>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={pdsDirectUrl()}
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD PRODUCT DISCLOSURE STATEMENT
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_BDF01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - BDF01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_SSL01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - SSL01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_NEW01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - NEW01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_KPI01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - KPI01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_LCF01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - LCF01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_ADP01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - ADP01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_WRF01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - WRF01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_SOM01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - SOM01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_KAN01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - KAN01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_HAW01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - HAW01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_ARN01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - ARN01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_CNG01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - CNG01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <Link
                to={
                  'https://assets.brickx.com/downloads/ADD_NML01.pdf'
                }
                target="_blank"
                className="doc-download"
              >
                <div className="button orange-button right-arrow-button">
                  DOWNLOAD ADDITIONAL DISCLOSURE DOCUMENT - NML01
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <a
                href="/pds/latest-pds-updates"
                className="button orange-button right-arrow-button"
              >
                RG46 SCHEDULE & OTHER DOCS
              </a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}
